<template>
  <!-- Table Container Card -->
  <b-overlay :show="loading" rounded="sm" no-fade>
    <div class="mt-1"></div>
    <div>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="12">
            <table class="table table-sm text-center">
              <thead>
                <tr>
                  <th colspan="1"><b-button @click="changeDate('previous')" class="btn btn-sm">&lt; </b-button></th>
                  <th colspan="9">
                    <span> Week Ending : {{ weekEnding }} </span>
                  </th>
                  <th colspan="1"><b-button @click="changeDate('next')" class="btn btn-sm"> &gt; </b-button></th>
                </tr>
              </thead>
            </table>
          </b-col>
        </b-row>

        <b-row v-for="user in timesheetData" :key="user.id">
          <b-col cols="12">
            <table class="table table-sm mt-1 mb-3 text-center">
              <thead>
                <tr>
                  <th colspan="12">
                    <span> {{ user.name }} </span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col" style="width:10%" class="text-dark bg-light">
                    Billable
                  </th>
                  <th scope="col" style="width:10%" class="text-dark bg-light">
                    Monday
                  </th>
                  <th scope="col" style="width:10%" class="text-dark bg-light">
                    Tuesday
                  </th>
                  <th scope="col" style="width:10%" class="text-dark bg-light">
                    Wednesday
                  </th>
                  <th scope="col" style="width:10%" class="text-dark bg-light">
                    Thursday
                  </th>
                  <th scope="col" style="width:10%" class="text-dark bg-light">
                    Friday
                  </th>
                  <th scope="col" style="width:10%" class="text-dark bg-light">
                    Saturday
                  </th>
                  <th scope="col" style="width:10%" class="text-dark bg-light">
                    Job Id/Description
                  </th>

                  <th scope="col" style="width:10%" class="text-dark bg-light">
                    Part/Category
                  </th>

                  <th scope="col" style="width:10%" class="text-dark bg-light"></th>
                </tr>

                <tr class="text-center" v-for="(row, index) in user.jobData" :key="index">
                  <!-- <td>$<input type="number" class="quoteInput form-control" placeholder="Labor" v-model="subItem.labor" /></td> -->
                  <td>
                    <div class="input-group d-flex align-items-center justify-content-center">
                      <b-form-checkbox v-model="row.billable" @change="row.jobId = null" inline :disabled="!row.editable" />

                    </div>
                  </td>

                  <td>
                    <div class="input-group">
                      <input :step="0.1" :disabled="!row.editable" type="number" @change="saveRowData(row, user)" v-model="row.monday" class="quoteInput form-control" />
                    </div>
                  </td>
                  <td>
                    <div class="input-group">
                      <input :step="0.1" :disabled="!row.editable" type="number" @change="saveRowData(row, user)" v-model="row.tuesday" class="quoteInput form-control" />
                    </div>
                  </td>
                  <td>
                    <div class="input-group">
                      <input :step="0.1" :disabled="!row.editable" type="number" @change="saveRowData(row, user)" v-model="row.wednesday" class="quoteInput form-control" />
                    </div>
                  </td>

                  <td>
                    <div class="input-group">
                      <input :step="0.1" :disabled="!row.editable" type="number" @change="saveRowData(row, user)" v-model="row.thursday" class="quoteInput form-control" />
                    </div>
                  </td>

                  <td>
                    <input :step="0.1" :disabled="!row.editable" type="number" @change="saveRowData(row, user)" v-model="row.friday" class="quoteInput form-control" />
                  </td>

                  <td>
                    <div class="input-group">
                      <input :step="0.1" :disabled="!row.editable" type="number" @change="saveRowData(row, user)" v-model="row.saturday" class="quoteInput form-control" />
                    </div>
                  </td>
                  <td>
                    <v-select v-if="row.billable" @input="row.category = null; saveRowData(row, user)" v-model="row.jobId" id="type" :options="timesheetValues" :clearable="false" />
                    <input v-else type="text" @input="debouncedSaveRowData(row, user)" v-model="row.reason" class="quoteInput form-control" />

                  </td>
                  <td>

                    <v-select v-if="!row.billable"  @input="debouncedSaveRowData(row, user)" v-model="row.category" id="type" :options="['Vacation','Personal Day','Sick Time','Other']" :clearable="false" />
                    <v-select v-else :disabled="!row.jobId" @input="debouncedSaveRowData(row, user)" v-model="row.category" id="type" :options="getOptions(row.jobId)" :clearable="false" />

                  </td>
                  <td>
                    <b-button :disabled="!row.editable" class="btn btn-sm" variant="danger" @click="deleteRow(user, row)">X </b-button>
                  </td>
                </tr>

                <tr style="background-color: lightgray;">
                  <!-- <td>$<input type="number" class="quoteInput form-control" placeholder="Labor" v-model="subItem.labor" /></td> -->
                  <td class="text-center">
                    <span></span>
                  </td>
                  <td class="text-center">
                    <span>{{ user.totalMonday }}</span>
                  </td>
                  <td class="text-center">
                    <span>{{ user.totalTuesday }}</span>
                  </td>
                  <td class="text-center">
                    <span>{{ user.totalWednesday }}</span>
                  </td>

                  <td class="text-center">
                    <span>{{ user.totalThursday }}</span>
                  </td>

                  <td class="text-center">
                    <span>{{ user.totalFriday }}</span>
                  </td>
                  <td class="text-center">
                    <span>{{ user.totalSaturday }}</span>
                  </td>
                  <td class="text-center">
                  </td>
                  <td class="text-center">
                    <b-button class="btn btn-sm" variant="success" @click="addNewRow(user)"> Add new </b-button>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12" md="8"> </b-col>
          <b-col cols="6" md="2" class="text-right">Week Total: {{ weekTotal }} hours</b-col>
          <b-col cols="6" md="2">
            <span style="border: none;" class="text-right"> </span>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import userStoreModule from '../userStoreModule';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BFormDatepicker } from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import ItemCropper from '@core/components/image-cropper/ItemCropper.vue';
import { ref } from '@vue/composition-api';
import axiosIns from '@/libs/axios';
import debounce from 'lodash/debounce';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
    BFormDatepicker,
    quillEditor,
    ItemCropper,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    return {};
  },

  data() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your message',
    };

    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },

      loading: true,
      baseURL: store.state.app.baseURL,
      dateDefault: null,
      tableData: [],
      timesheetValues: [],
      weekEnding: null,
      weekTotal: 0,
      materials : [],

      timesheetData: [
        {
          name: 'Cuneyt',
          id: 1,
          jobData: [
            {
              editable:true,
              billable: true,
              reason: null,
              category: null,
              monday: 0,
              tuesday: 0,
              wednesday: 0,
              thursday: 0,
              friday: 0,
              saturday: 0,
              jobId: null,
            },
          ],

          totalWeek: 0,
          totalMonday: 0,
          totalTuesday: 0,
          totalWednesday: 0,
          totalThursday: 0,
          totalFriday: 0,
          totalSaturday: 0,
        },
      ],
      nextId: 2,
      tableFields: [
        {
          key: 'item',
          label: 'Part',
          class: 'text-right',
          thStyle: 'width:25%',
        },
        {
          key: 'labor',
          label: 'Labor',
          class: 'text-right',
          thStyle: 'width:10%',
        },
        {
          key: 'hours',
          label: 'Hours',
          class: 'text-right',
          thStyle: 'width:5%',
        },

        {
          key: 'materials',
          label: 'Materials',
          class: 'text-right',
          thStyle: 'width:10%',
        },
        {
          key: 'tooling',
          label: 'Tooling',
          class: 'text-right',
          thStyle: 'width:10%',
        },
        {
          key: 'o_process',
          label: 'Outside Process',
          class: 'text-right',
          thStyle: 'width:10%',
        },
        {
          key: 'sub_total',
          label: 'Subtotal',
          class: 'text-right',
          thStyle: 'width:10%',
        },

        {
          key: 'quantity',
          label: 'Quantity',
          class: 'text-right',
          thStyle: 'width:10%',
        },
        {
          key: 'total',
          label: 'total',
          class: 'text-right',
          thStyle: 'width:10%',
        },
      ],

      percent: {
        decimal: '.',
        separator: ',',
        suffix: '%',
        precision: 2,
        masked: false,
        min: 0,
        max: 100,
        minimumFractionDigits: 2,
      },

      money: {
        decimal: '.',
        separator: ',',
        prefix: '$',
        precision: 2,
        masked: false,
        minimumFractionDigits: 2,
      },

      quantity: {
        precision: 2,
        masked: false,
        reverseFill: false,
        prefill: false,
        min: 0,
      },
    };
  },

  watch: {
    //   'timesheetData.jobData': {
    //   handler: function(newVal, oldVal) {
    //     newVal.forEach((subItem) => {
    //       subItem.sub_total = Number(subItem.labor) * Number(subItem.hours) + Number(subItem.materials) + Number(subItem.tooling) + Number(subItem.one_time);
    //       subItem.total = Number(subItem.quantity) * subItem.sub_total + Number(subItem.o_process);
    //     });
    //   },
    //   deep: true,
    // },
  },

  methods: {
    debouncedSaveRowData: debounce(function(row, user) {
        this.saveRowData(row, user);
    }, 500), 
    getJobNumbers() {
      this.loading = true;
      store
        .dispatch('user/getJobNumbers', { weekEnding: this.weekEnding })
        .then((response) => {
          this.timesheetValues = response.data.jobNumbers;
          this.timesheetData = response.data.timesheetData;
          this.weekTotal = response.data.weekTotal;
          this.materials = response.data.materials;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    addNewRow(user) {
      this.timesheetData[this.timesheetData.indexOf(user)].jobData.push({
        editable:true,
        billable: true,
        reason: null,
        category: null,
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        jobId: null,
      });
    },
    deleteRow(user, row) {
      store
        .dispatch('user/deleteRowData', { rowData: row })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      this.getJobNumbers();
    },

    saveRowData(row, user) {
      this.loading = true;
      row.weekEnding = this.weekEnding;
      row.userId = user.id;
      store
        .dispatch('user/saveRowData', { rowData: row })
        .then((response) => {
          this.getJobNumbers();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changeDate(to) {
      var newDate = Date.parse(this.weekEnding);

      if (to == 'next') {
        this.weekEnding = new Date(newDate + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
        this.getJobNumbers();
      } else {
        this.weekEnding = new Date(newDate - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
        this.getJobNumbers();
      }
    },

    getOptions(id){
      if (id && this.materials.length > 0) {
      const foundMaterial = this.materials.find((e) => e.id === id).partNames;
      return foundMaterial;
  }

    }
  },

  created() {
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay() + 1;
    var last = first + 6; // last day is the first day + 6
    var lastday = new Date(curr.setDate(last)).toISOString().split('T')[0];

    this.weekEnding = lastday;
    this.getJobNumbers();
  },

  computed: {




  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.title {
  font-weight: 600;
}

.border {
  padding: 0.5rem;
}

.bigTitle {
  color: #a51c30;

  font-size: 1.5rem;
}
// text-center mt-5 p-1 bg-light-secondary
.add-new-line-button {
  // text-align: center;
  margin-top: 2rem;
  // height: 3rem;
  // border: 1px solid #a51c3050;
  // border-style: dashed;
  // cursor: pointer;

  outline: none;
  display: inline-flex;

  line-height: 1.5;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-box-align: center;
  align-items: center;
  border: 1px dashed #a51c3080;
  height: 3rem;
  padding: 0px 1.5rem;
  width: 100%;
  background: rgb(247, 250, 255);
  color: #a51c30;
}

.add-new-line-button:hover {
  background: rgb(243, 245, 247);
  color: rgb(97, 113, 131);
}

.quoteInput {
  border: none;
  background-color: #ffffff;
  border-bottom: 1px solid lightgray;
  text-align: center;
}

.form-control:focus {
  border-color: #a51c30 !important;
}

.form-control {
  padding: 0px;
}

#quil-content ::v-deep {
  > .ql-container {
    .ql-editor {
      height: 300px !important;
    }
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    background-color: white;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  + #comment-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.prependIcon {
  border: none;
  font-size: 1.2rem;
  margin-right: 0px;
  padding-right: 3px;
  // margin-top: 2px;

  border-bottom: 1px solid lightgray;
}

.uploadButton {
  position: absolute;
  top: -8px;
  right: 85px;
  margin: 2px;
  padding: 3px;
  border-radius: 6px;
  color: #fff;
  background-color: #ff0000;
  cursor: pointer;
  opacity: 0.5;
}
.uploadButton:hover {
  opacity: 1;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
